<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Imprimir Pedido</h4>
        <el-row type="flex" justify="start">
          <el-dropdown
            type="primary"
            class="mr-8"
            size="medium"
            @command="(v) => executeUserCommand(v)"
          >
            <el-button size="medium" type="primary">
              Imprimir
              <el-icon class="el-icon-arrow-down"></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="a4"
                  >Pedido em Folha A4</el-dropdown-item
                >
                <el-dropdown-item command="bematech"
                  >Pedido na Bematech</el-dropdown-item
                >
                <el-dropdown-item command="nf" :disabled="!order?.invoice?.url"
                  >Nota Fiscal</el-dropdown-item
                >
                <el-dropdown-item divided disabled>Boletos</el-dropdown-item>
                <el-dropdown-item
                  :command="`billets-${id}`"
                  v-for="(billet, id) in OrderBillets"
                  :key="billet"
                  :disabled="!billet.billet_pdf_url"
                  >{{
                    `${currencyFormatter.format(Number(billet.amount))} -
                    ${dateFormatter.format(new Date(billet.expires_at))}`
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-row>
      </el-row>
    </template>
    <el-empty v-if="!order" description="Pedido não encontrado"></el-empty>
    <div v-else>
      <el-descriptions
        border
        :column="3"
        title="Resumo de Pagamentos"
        direction="vertical"
      >
        <el-descriptions-item>
          <template #label> Nº Pedido </template>
          {{ Order.code }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Data do Pedido </template>
          {{ dateFormatterShort.format(new Date(Order.created_at)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Vendedor </template>
          {{ Order.seller.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Cliente </template>
          {{ Order.client.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Data da Entrega </template>
          {{
            Order.delivered_at
              ? dateFormatterShort.format(new Date(Order.delivered_at))
              : "-"
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Vencimento </template>
          {{ printOrderPaymentExpirations() }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Forma Pagamento </template>
          {{ Order.payment_method_formatted }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label> Motorista </template>
          {{ Order.driver?.employee?.name || "NÃO DEFINIDO" }}
        </el-descriptions-item>
      </el-descriptions>

      <el-table
        :data="OrderProducts"
        show-summary
        :summary-method="buildSummary"
        :row-class-name="
          ({ row: payment }) => (payment.paid_at ? 'success' : '')
        "
        style="width: 100%; z-index: 0"
        :cell-style="() => 'text-align:center;'"
      >
        <el-table-column
          label="produto"
          min-width="250"
          :formatter="(row) => buildProductName(row)"
        >
        </el-table-column>
        <el-table-column
          label="quantidade"
          :formatter="
            (product) => weightFormatter.format(Number(product.weight))
          "
        >
        </el-table-column>
        <el-table-column
          label="valor"
          :formatter="(r) => currencyFormatter.format(Number(r.amount))"
        >
        </el-table-column>
        <el-table-column
          label="total"
          prop="total_amount"
          :formatter="(r) => currencyFormatter.format(Number(r.total_amount))"
        >
        </el-table-column>
      </el-table>

      <el-row style="margin: 32px" type="flex" justify="start">
        <small>Pedido recebido por:</small>
      </el-row>
      <el-row style="margin: 32px" type="flex" justify="start">
        <el-col :md="12" :xs="24" align="left" class="md-12-print p-10">
          <small>Nome: </small>
          <div
            style="width: 100%; height: 1px; border-bottom: 2px solid black"
          ></div>
        </el-col>
        <el-col :md="12" :xs="24" align="left" class="md-12-print p-10">
          <small
            >Assinatura:
            <div
              style="width: 100%; height: 1px; border-bottom: 2px solid black"
            ></div
          ></small>
        </el-col>
      </el-row>
    </div>

    <order-payment-modal
      :showModal="showOrderPaymentModal"
      @should-update="fetchOrder()"
      @close-modal="showOrderPaymentModal = false"
      :order="order"
    ></order-payment-modal>
  </el-card>
</template>

<script>
import OrderService from "../services/orders";
import SystemService from "../services/system";
import {
  currencyFormatter,
  dateFormatter,
  dateFormatterShort,
  weightFormatter,
} from "../utils/formatters";
import OrderPaymentModal from "./modals/OrderPaymentModal.vue";
export default {
  name: "PrintOrder",
  components: { OrderPaymentModal },
  data: () => ({
    isLoading: false,
    searchOrder: null,
    showOrderPaymentModal: false,
    order: null,
    currencyFormatter,
    dateFormatter,
    dateFormatterShort,
    weightFormatter,
  }),
  computed: {
    OrderId() {
      return this.$route.params.orderId;
    },
    Order() {
      return this.order || {};
    },
    OrderBillets() {
      return this.Order.billet || [];
    },
    OrderProducts() {
      return this.Order.products || [];
    },
  },
  mounted() {
    if (this.OrderId) this.fetchOrder();
  },
  methods: {
    printOrderPaymentExpirations() {
      return (
        this.Order.payments
          ?.map((p) =>
            this.dateFormatter.format(
              new Date(p.expires_at || this.Order.delivered_at || Date.now())
            )
          )
          .join(",") || "-"
      );
    },
    executeUserCommand(command) {
      switch (command) {
        case "nf":
          this.openDownloadPage(this.order?.invoice?.url);
          break;
        case "a4":
          this.printPage();
          break;
        case "bematech":
          this.printInServer();
          break;
        default:
          var [userCommand, id] = command.split("-");

          if (userCommand === "billets")
            this.openDownloadPage(this.order.billet?.[id]?.billet_pdf_url);
      }
    },
    billetStatustype() {
      if (!this.order?.billet) return "default";

      switch (this.order?.billet?.status) {
        case "created":
        case "emitted":
          return "warning";
        case "registered":
          return "primary";
        case "paid":
          return "success";
        case "error":
        case "expired":
          return "danger";
      }
    },
    openDownloadPage(url) {
      if (url) window.open(url, "_blank");
    },
    printInServer() {
      if (this.$store.state.user.printer) {
        SystemService()
          .Printers(this.$store.state.user.printer.uid)
          .Job()
          .create({
            layout: "orders",
            print_content: JSON.stringify({
              ...this.Order,
              driver: {
                ...this.Order.driver,
                name: this.Order.driver.employee?.name,
              },
            }),
          })
          .then(() => {
            this.$message.success("Impressão enviada para a impressora");
          });
      } else {
        SystemService()
          .Print()
          .Order(
            new URLSearchParams({
              vendedor: this.Order.seller.name,
              atendente: this.Order.user?.name || "-",
              data: this.dateFormatterShort.format(
                new Date(this.Order.created_at)
              ),
              data_entrega: this.dateFormatterShort.format(
                new Date(this.Order.delivered_at)
              ),
              cod_pedido: this.Order.seq,
              numero_pedido: this.Order.code.toString(),
              cliente:
                this.Order.client.legal?.social_name || this.Order.client.name,
              vencimento: this.dateFormatterShort.format(
                new Date(this.Order.deliver_at)
              ),
              motorista: this.Order.driver?.employee?.name || "-",
              valorTotal: this.currencyFormatter.format(
                Number(this.Order.total_amount)
              ),
              ...this.buildOrderProductsObject(),
            })
          );
      }
    },
    buildOrderProductsObject() {
      return (
        this.OrderProducts?.reduce((t, p, i) => {
          const keyAmount = `produtos[${this.buildProductName(
            p
          )}][valor][${i}]`;
          const keyQtd = `produtos[${this.buildProductName(p)}][qtd][${i}]`;
          const keyTotalAmount = `produtos[${this.buildProductName(
            p
          )}][totalProduto][${i}]`;

          t[keyAmount] = this.currencyFormatter.format(Number(p.amount));
          t[keyQtd] = this.weightFormatter.format(Number(p.weight));
          t[keyTotalAmount] = this.currencyFormatter.format(
            Number(p.total_amount)
          );

          return t;
        }, {}) || {}
      );
    },
    printPage() {
      print();
    },
    buildSummary() {
      return [
        "Total do Pedido:",
        "",
        "",
        this.currencyFormatter.format(Number(this.Order.total_amount)),
      ];
    },
    buildProductName({ mounted: m }) {
      const { product: p } = m;

      return `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
        m.storage ?? ""
      }`;
    },
    openOrderPaymentModal() {
      this.showOrderPaymentModal = true;
    },
    async fetchOrder() {
      this.isLoading = true;

      const { order } = await OrderService(this.OrderId).get();

      if (order) this.order = order;
      else this.order = null;

      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
<style scoped>
@media print {
  .md-12-print {
    max-width: 49% !important;
  }

  .el-card >>> .el-card__header {
    display: none;
  }
}

.p-10 {
  padding: 10px;
}
</style>
